export const getUrlParams = (url, rid = null) => {
    const requiredParams = {
        refid1: '0',
        refid2: '0',
        refid3: '0',
        hash: undefined,
        rid,
    };

    const urlObj = new URL(url);
    const params = new URLSearchParams(urlObj.search);
    const result = {};

    Object.keys(requiredParams).forEach((param) => {
        const paramValue = params.get(param);

        // update requiredParams from url values
        if (paramValue !== null) {
            result[param] = paramValue;
        } else {
            result[param] = requiredParams[param];
        }

        // get, update localStorage values if necessary
        if (
            result[param] !== '0' &&
            result[param] !== null &&
            result[param] !== undefined
        ) {
            localStorage.setItem(param, result[param].toString());
        } else if (localStorage.getItem(param)) {
            result[param] = localStorage.getItem(param);
        }
    });

    return result;
};

const normalizeKey = (key) => {
    const normalizedKey = key
        .replace(/^(game-popup-|game-)/, '')
        .replace(/-([a-z])/g, (_, letter) => letter.toUpperCase());

    return normalizedKey.charAt(0).toUpperCase() + normalizedKey.slice(1);
};

/**
 * Filter an input object for keys starting with "game-", convert those keys from kebab-case to PascalCase, and return a new object with the converted keys and their corresponding values
 *
 * @param apiConfig - dynamic config object from sharpstar api getOffers
 *
 * return {[key]: value}
 */
export const getGameInitValues = (apiConfig) => {
    return Object.entries(apiConfig).reduce((result, [key, value]) => {
        if (key.startsWith('game-popup-')) {
            const normalizedPopupKey = normalizeKey(key);

            result['popup'] = {
                ...result['popup'],
                [normalizedPopupKey]: value,
            };
        } else if (key.startsWith('game-')) {
            const normalizedKey = normalizeKey(key);

            result[normalizedKey] = value;
        }
        return result;
    }, {});
};

/**
 * Filters game related "key: value" pairs from sharpstar config to set as sharpstar lead attributes
 *
 * return {[string]: string}
 */
export const filterGameRelatedAttributes = (inputObj) => {
    const outputObj = {};

    for (const key in inputObj) {
        if (
            (key.startsWith('game-') || key.startsWith('build-')) &&
            !key.includes('addressables')
        ) {
            outputObj[key] = inputObj[key];
        }
    }

    return outputObj;
};

export const localStorageValueReset = (key, value) => {
    const localStorageValue = localStorage.getItem(key);

    !localStorageValue && localStorage.setItem(key, value);

    return localStorageValue;
};
