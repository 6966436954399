import React, { useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import polyglot from 'App/Helpers/languages';
import BubbleLoader from '../BubbleLoader';
import './modal.scss';

const modalRoot = document.getElementById('modal-root');

const Modal = ({
    title,
    content,
    onClose,
    isAntiClose,
    lang,
    noTitle,
    noImg,
}) => {
    const el = useMemo(() => {
        const div = document.createElement('div');
        div.classList.add('modal-con');
        return div;
    }, []);

    useEffect(() => {
        modalRoot.appendChild(el);
        return () => {
            modalRoot.removeChild(el);
        };
    }, [el]);

    return ReactDOM.createPortal(
        <div className='modal-dialog modal-dialog-scrollable' role='document'>
            <div className='modal-content'>
                <div className='modal-header'>
                    <h5 className='modal-title' id='ModalScrollableTitle'>
                        {title}
                    </h5>
                    {!isAntiClose && (
                        <button
                            type='button'
                            className='close'
                            onClick={onClose}
                            aria-label='Close'>
                            <span aria-hidden='true'>&times;</span>
                        </button>
                    )}
                </div>
                <div
                    className={`modal-body ${noTitle ? 'no-title' : ''} ${noImg ? 'no-img' : ''} ${isAntiClose ? 'no-close' : ''}`}>
                    {content ? (
                        <div dangerouslySetInnerHTML={{ __html: content }} />
                    ) : (
                        <BubbleLoader
                            backgroundColor={'white'}
                            loaderColor={'#ccc'}
                            isOverlay={false}
                        />
                    )}
                </div>
                {!isAntiClose && (
                    <div className='modal-footer'>
                        <button
                            type='button'
                            className='modal-btn'
                            onClick={onClose}>
                            {lang
                                ? polyglot.t(`${lang}.modals_slug.closeBtn`)
                                : 'Close'}
                        </button>
                    </div>
                )}
            </div>
        </div>,
        el
    );
};

export default Modal;
